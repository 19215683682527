import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputField, ErrorMessage,
} from 'anf-core-react';
import formatExpiry from './helpers/formatExpiry';
import { isValidExpiry } from './helpers/cardValidations';

export default function ExpiryField({
  expiryDate,
  label,
  errorMessage,
  index,
  onChange,
}) {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (event) => {
    let input = formatExpiry(event.target.value);

    // Handle deleting of characters
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      if (input.endsWith(' / ')) {
        input = input.slice(0, input.length - 3);
      } else if (input.endsWith('/')) {
        input = input.slice(0, input.length - 1);
      }
    }

    onChange(input);
    // Update state with formatted input
    return input;
  };

  const validateChange = (event) => {
    const input = event.target.value;
    setIsInvalid(!isValidExpiry(input));
  };

  return (
    <InputField
      label={label}
      name={`creditCard[${index}].cardExpiryFmt`}
      id={`expiry-field-${index}`}
      value={expiryDate}
      onChange={handleChange}
      onBlur={validateChange}
      type="tel"
      isInvalid={isInvalid}
      autoComplete=""
    >
      <ErrorMessage id="expiry-error-message">
        {errorMessage}
      </ErrorMessage>
    </InputField>
  );
}

ExpiryField.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
