import React, { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import ORDERCONFIRMATIONPAGE_DATA_QUERY from '../../gql/orderConfirmationPage.gql';
import useLog from '../useLog/useLog';
import OrderConfirmationContext from '../../context/OrderConfirmationContext';
import Survey from './Survey/Survey';
import SmsOptIn from './SmsOptIn/SmsOptIn';
import CreatePortal from '../Common/CreatePortal/CreatePortal';
import OrderInfo from './OrderInfo/OrderInfo';
import Footer from './Footer/Footer';
import RightRail from './RightRail/RightRail';
import {
  ERROR_MESSAGE as errorMessage,
  LOADING_MESSAGE as loadingMessage,
} from '../Common/Messages/Messages';
import trackAction from '../../tools/analytics';
import $window from '../../tools/window';
import MarketingSubscription from './MarketingSubscription/MarketingSubscription';
import OrderReview from './OrderReview/OrderReview';
import PointsReward from './PointsReward/PointsReward';

export default function OrderConfirmationPage() {
  const [orderConfirmationData, setOrderConfirmationData] = useState(null);
  const OrderInfoPortal = CreatePortal(OrderInfo, 'ocn-order-info-mfe-portal');
  const FooterPortal = CreatePortal(Footer, 'ocn-footer-mfe-portal');
  const RightRailPortal = CreatePortal(RightRail, 'ocn-right-rail-mfe-portal');
  const PointsRewardPortal = CreatePortal(PointsReward, 'ocn-points-reward-mfe-portal');
  const logger = useLog('orderConfirmationPage.root');
  const [cookies] = useCookies();
  const ocnProviderValues = useMemo(() => ({
    orderConfirmationData,
  }), [orderConfirmationData]);
  const { loading, error, data: queryData } = useQuery(ORDERCONFIRMATIONPAGE_DATA_QUERY, {
    context: { batch: true },
    fetchPolicy: 'no-cache',
    ssr: false,
    onCompleted: (data) => {
      const resOCN = { ...data };
      setOrderConfirmationData(resOCN);
      $window.digitalData?.merge('cart', {
        origin: 'orderConfirmation',
        data,
        action: 'load',
      });
      const totalItemsInBagAsNumber = parseInt(data?.orderTotals?.totalItemsInBag, 10);
      // Triggering MiniBag update item count to 0
      const updateItemCount = new CustomEvent('miniBag:updateItemCount:done', {
        detail: {
          items: [], // Sending manual empty array to match the format for listening event.
        },
      });
      window.dispatchEvent(updateItemCount);
      trackAction('order_confirmation_loaded', {
        ...data?.orderTotals,
        // Calculating total before discount by adding negative promoAmount
        // Example: If orderTotal after discount is 50.6 and
        // promo amount is 0.40, then grandTotalBeforeDiscount is 51
        grandTotalBeforeDiscount: data?.orderTotals?.grandTotal - data?.orderTotals?.promoAmount,
        totalItems: totalItemsInBagAsNumber,
        couponCode: data?.promoInfo?.map((promoItem) => promoItem?.promotionCode).join(',') || '',
        sms_flag: data?.smsOptIn?.isSelected,
        promotion_flag_loyalty: data?.promoInfo?.some((promoItem) => promoItem?.isRewardPromo),
        user_marketing_flag: !!Object.values(cookies?.subscribe)?.find((marketingEmails) => marketingEmails === 'Y'),
      });
    },
    onError: (err) => {
      logger.error(`ERR: ORDERCONFIRMATIONPAGE_DATA_QUERY: ${JSON.stringify(err)}`);
    },
  });

  if (loading) return loadingMessage;
  if (error) return errorMessage;
  if (!queryData) return null;

  const isGuestUser = queryData?.userData?.isLoggedIn === false;

  return (
    orderConfirmationData && (
      <OrderConfirmationContext.Provider value={ocnProviderValues}>
        <section className="order-confirm-wrapper" data-testid="order-confirmation-page-wrapper">
          {isGuestUser && <MarketingSubscription />}
          <SmsOptIn />
          <Survey />
          {/* OrderInfo, Footer and RightRail component can not be put in order unless we
          convert the whole page in MFE. Until then, we have to use Portal to insert these
          snippets at the correct corresponding DOM node in CRS app. We are keeping them behind
          a common store attribute for show/hide in both MFE and CRS */}
          {orderConfirmationData?.switches?.loadCompleteMFEOrderConfirmationPage && (
            <>
              <OrderReview />
              <OrderInfoPortal />
              <FooterPortal />
              <RightRailPortal />
              <PointsRewardPortal />
            </>
          )}
        </section>
      </OrderConfirmationContext.Provider>
    )
  );
}
