import React, { useContext, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import IconHeading from '../../Common/IconHeading/IconHeading';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';
import SignInToAssociatePoints from './SignInToAssociatePoints';

const ORDERCONFIRMATIONPAGE_DOES_USER_EXIST = gql`
  query DoesUserExist($email: String!) {
    userExists(email: $email)
  }
`;

function PointsReward() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const [userExists, setUserExists] = useState(false);

  const {
    orderContact,
    pointsReward,
    userData,
  } = orderConfirmationData;

  useQuery(ORDERCONFIRMATIONPAGE_DOES_USER_EXIST, {
    variables: { email: orderContact?.email },
    onCompleted: (data) => {
      setUserExists(data.userExists);
    },
  });

  const { isLoggedIn = false } = userData;

  const pointsInfoState = useMemo(() => {
    if (userExists) {
      return (isLoggedIn) ? 'LOGGED_IN' : 'SIGN_IN';
    }

    return 'CREATE_ACCOUNT';

    // add 'CREATE_ACCOUNT' and 'ACCOUNT_CREATED' logic here
  }, [isLoggedIn, userExists]);

  return (
    <div className="ocn-points-reward" data-testid="points-to-account">
      <div className="ocn-points-reward__header">
        <IconHeading
          image={pointsReward?.pointsRewardImage}
          variant="medium"
          layout="vertical"
        >
          <Tmnt tmnt={pointsReward?.pointsRewardHeader} />
        </IconHeading>
      </div>

      {pointsInfoState === 'LOGGED_IN' && (
        <div className="ocn-points-reward__description">
          <p><Tmnt tmnt={pointsReward?.pointsRewardDesc} /></p>
        </div>
      )}

      {pointsInfoState === 'SIGN_IN' && <SignInToAssociatePoints />}

      {/* include logic and import for appDownloadLink component here */}

      {/* include logic and import for createAccount component here */}
    </div>
  );
}

export default PointsReward;
