import { Button, Icon } from 'anf-core-react';
import ReactDOM from 'react-dom';
import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  useLazyQuery,
} from '@apollo/client';
import SwitchTestContext from '../../../context/SwitchTestContext';
import MiniBag from '../MiniBag';
import Tmnt from '../../Tmnt/Tmnt';
import useLog from '../../useLog/useLog';
import { useIsM } from '../../../hooks/useBreakPoint';
import trackAction from '../../../tools/analytics';
import MiniBagInstrumentedRecommendations from '../../Recommendations/MiniBagInstrumentedRecommendations';

import { MINIBAG_QUERY, MINIBAG_LOCAL_STORAGE_QUERY } from '../../../gql/miniBagIcon.gql';
import DebugToaster from '../../Common/DebugToaster/DebugToaster';

export default function MiniBagWithIcon() {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const miniBagMobileEnabled = digitalData ? digitalData['chk-mfe-miniBag-mobile'] : false;
  const miniBagMFEId = 'miniBag-toaster';
  const [mounted, setMounted] = useState(false);
  const [miniBagData, setMiniBagData] = useState(null);
  const [shoppingBagUrl, setShoppingBagUrl] = useState(null);
  const [localStorageKey, setLocalStorageKey] = useState(null);

  const isShoppingBagPage = () => window.location.href.includes('/OrderItemDisplayView');

  const isCheckoutPage = () => window.location.href.includes('/OrderCheckoutDisplayView');

  const isOCNPage = () => window.location.href.includes('/OrderConfirmationDisplayView');

  const isPAYPALPage = () => window.location.href.includes('/ProcessPayPalReturnAndOrderExpress');

  const isNotLargeScreen = useIsM();

  const logger = useLog('MiniBagIcon.root');

  const [fetchMiniBagQuery] = useLazyQuery(MINIBAG_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
  });

  const [fetchMiniBagLSQuery] = useLazyQuery(MINIBAG_LOCAL_STORAGE_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
  });

  const openToaster = useCallback(() => {
    if (!isShoppingBagPage() && !isCheckoutPage() && !isOCNPage() && !isPAYPALPage()) {
      const event = new CustomEvent(`${miniBagMFEId}:open`, { detail: '' });
      window.dispatchEvent(event);
    }
  }, []);

  const updateMiniBagCount = useCallback(() => {
    fetchMiniBagLSQuery().then((res) => {
      logger.debug('MINIBAG_LOCAL_STORAGE_QUERY FETCH RESULT', res);
      const numberOfItems = res?.data?.bagItems?.items
        && Object.keys(res?.data?.bagItems?.items).length !== 0
        ? res?.data?.bagItems?.items?.length : '0';
        // Updating the miniBagData with new numberOfItems
      const miniBagInfo = {
        itemsInBag: numberOfItems,
        textFor: res?.data?.textFor,
      };
      setMiniBagData(miniBagInfo);
      // setting the miniBagData in localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(miniBagInfo));
    });
  }, [fetchMiniBagLSQuery, localStorageKey, logger]);

  const getLocalStorageKey = useCallback(() => {
    fetchMiniBagQuery().then((res) => {
      logger.debug('MINIBAG_BAG_QUERY FETCH RESULT', res);
      setShoppingBagUrl(res?.data?.viewBagButton?.bagUrl);
      setLocalStorageKey(res?.data?.miniBagIcon?.localStorageKey);
    });
  }, [fetchMiniBagQuery, logger]);

  useEffect(() => {
    // URL query should render always to have consistency throughout language translation.
    getLocalStorageKey();
  }, [logger, getLocalStorageKey]);

  useEffect(() => {
    setMounted(true);
    if (!localStorageKey) return;
    const localStorageMiniBagData = localStorage.getItem(localStorageKey);
    let parsedData;
    try {
      parsedData = JSON.parse(localStorageMiniBagData);
      // Handling JSON parse error when localStorage is not valid JSON
    } catch (e) {
      logger.debug('Invalid JSON');
      parsedData = null;
    }
    if (parsedData === null || Object.keys(parsedData).length === 0) {
      // we should fetch the query if localStorage is null or empty.
      updateMiniBagCount();
    } else {
      setMiniBagData(parsedData);
    }
  }, [localStorageKey, logger, openToaster, updateMiniBagCount]);

  useEffect(() => {
    const handleUpdateItemCountDone = (evt) => {
      /* Everytime a product gets added, we need  to refetch the localStorageKey based on
      the current userId, because whenever we add a product, generic user becomes guest user.
      Hence we can not update the localStorageKey with the current userId.
      */
      getLocalStorageKey();
      if (localStorageKey?.includes('-1002')) return; // never ever update product count for generic user
      const numberOfItems = evt.detail.items ? evt.detail.items.length : 0;
      logger.debug('Listening to miniBag:updateItemCount:done');
      // updating both localStorage and State with new numberOfItems
      const updatedMiniBagData = {
        ...miniBagData,
        itemsInBag: numberOfItems,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedMiniBagData));
      setMiniBagData(updatedMiniBagData);
    };

    const handleProductAddedToCart = (evt) => {
      /* Everytime a product gets added, we need  to refetch the localStorageKey based on
      the current userId, because whenever we add a product, generic user becomes guest user.
      Hence we can not update the localStorageKey with the current userId.
      */
      getLocalStorageKey();
      logger.debug('Listening to miniBag:productAddedToCart', evt);
      if (isNotLargeScreen) {
        // For mobile/tablet, we just update the miniBagCount
        if (localStorageKey?.includes('-1002')) return; // never ever update product count for generic user
        updateMiniBagCount();
      } else {
        // Only for desktop, we open the toaster
        openToaster();
      }
    };
    window.addEventListener('miniBag:updateItemCount:done', handleUpdateItemCountDone);
    window.addEventListener('miniBag:productAddedToCart', handleProductAddedToCart);
    // PDP page dispatches mfe:addToBag:displayMobileNotification event for mobile notification,
    // so we need to listen to it separately
    window.addEventListener('mfe:addToBag:displayMobileNotification', handleProductAddedToCart);
    return function removeListener() {
      window.removeEventListener('miniBag:updateItemCount:done', handleUpdateItemCountDone);
      window.removeEventListener('miniBag:productAddedToCart', handleProductAddedToCart);
      window.removeEventListener('mfe:addToBag:displayMobileNotification', handleProductAddedToCart);
    };
  }, [isNotLargeScreen,
    localStorageKey, logger, miniBagData, openToaster, getLocalStorageKey, updateMiniBagCount]);

  if (!mounted) { return null; }
  const itemsInBag = miniBagData?.itemsInBag || 0;

  const handleMiniBagClick = () => {
    if (isCheckoutPage() || isPAYPALPage() || isOCNPage()) {
      window.location.href = shoppingBagUrl;
    } else if (isShoppingBagPage()) {
      window.location.reload();
    } else {
      openToaster();
      trackAction('minibag_click', {
        data_text: 'minibag toaster',
        data_action: 'open',
        event_type: 'click',
      });
    }
  };

  const handleMobileMiniBagClick = () => {
    window.location.href = shoppingBagUrl;

    trackAction('universal_click', {
      data_text: 'minibag mobile',
      data_action: 'open',
      event_type: 'click',
    });
  };

  const miniBagIconClass = `rs-nav__item-count--badge js-rs-nav__item-count--bag rs-nav__item-count rs-nav__item-count--bag ${itemsInBag > 0 && !isOCNPage() ? 'has-count' : ''}`;

  const miniBagContent = () => (
    <div className="minibag-icon-wrapper">
      {/*
      1. Here clicking the button would trigger the Toaster to open. Also focussing on the button
          triggers the Toaster to open as well while operating with tab.
      2. Hidden arrow button is not needed for a11y compliance.
       Read : If the top-level navigation item only actuates a modal and
      *does not* navigate to a new page when activated, mark it up as a button (`<button>`). */}
      <button
        className="rs-nav-icon rs-nav-icon--shopping-bag rs-nav-cat-menu-item minibag-nav-mfe"
        data-aui="shopping-bag-nav-link"
        aria-describedby="rs-nav__item-count mini-bag__a11y-desc"
        aria-expanded="false"
        data-testid="navigation-button"
        onClick={handleMiniBagClick}
      >
        <Icon
          icon="bag-outline"
          labelText="MiniBag"
          size="m"
        />
        <p className="mini-bag__a11y-desc" id="mini-bag__a11y-desc">
          {miniBagData?.textFor && <Tmnt tmnt={miniBagData?.textFor?.itemsInBagTMNT} />}
        </p>
        <span data-testid="bag-item-count-b-test" id="rs-nav__item-count-largescreen" className={miniBagIconClass} aria-hidden="true">{itemsInBag}</span>
        <span className="screen-reader-text">
          {miniBagData?.textFor && <Tmnt tmnt={miniBagData?.textFor?.shoppingBag} />}
        </span>
      </button>
      <div data-testid="minibag-under-icon-block" className="minibag-under-icon-block">
        <MiniBag />
      </div>
      <DebugToaster />
    </div>
  );

  const mobileMiniBagPortalContent = () => {
    const portalElement = document?.getElementById('checkout-mfe-mobile-minibag-portal');
    if (!portalElement) return null;

    return ReactDOM.createPortal(
      <div data-testid="mobile-navigation-button">
        <Button
          classList="link-as--button rs-nav-icon rs-nav-icon--shopping-bag-alt rs-nav-icon--r hide-lg mobile-mini-bag"
          variant="borderless"
          icon="bag-outline"
          onClick={handleMobileMiniBagClick}
        >
          <p className="mini-bag__a11y-desc" id="mini-bag__a11y-desc">
            {miniBagData?.textFor && <Tmnt tmnt={miniBagData?.textFor?.itemsInBagTMNT} />}
          </p>
          <span
            data-testid="bag-item-count-mobile"
            id="rs-nav__item-count-smallscreen"
            className={miniBagIconClass}
            aria-hidden="true"
          >
            {itemsInBag}
          </span>
          <span className="screen-reader-text">
            {miniBagData?.textFor && <Tmnt tmnt={miniBagData?.textFor?.shoppingBag} />}
          </span>
        </Button>
      </div>,
      portalElement,
    );
  };

  const recommendationsProps = {
    clientId: 'miniBag-1',
    placements: [
      'cart_page.mini_bag1',
      'cart_page.mini_bag2',
      'cart_page.mini_bag3',
      'cart_page.mini_bag4',
      'cart_page.mini_bag5',
      'cart_page.mini_bag6',
    ],
  };

  return (
    <div data-testid="minibag-with-icon-comp" className="minibag-with-icon-comp">
      {isNotLargeScreen ? (miniBagMobileEnabled && mobileMiniBagPortalContent()) : miniBagContent()}

      <MiniBagInstrumentedRecommendations
        clientId={recommendationsProps.clientId}
        placements={recommendationsProps.placements}
      />
    </div>
  );
}
