import React, { useState, useContext } from 'react';
import {
  useMutation,
} from '@apollo/client';
import { CheckboxGroup, Checkbox, ErrorMessage } from 'anf-core-react';
import MarketingSpot from '../../Common/MarketingSpot/MarketingSpot';
import TileInputGroup from '../TileInputField/TileInputGroup';
import TileInput from '../TileInputField/TileInput';
import Loader from '../../Common/Loader/Loader';
import { CHARITY_UPDATE_MUTATION } from '../../../gql/charity.gql';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import useLog from '../../useLog/useLog';
import trackAction from '../../../tools/analytics';
import $window from '../../../tools/window';

export default function Charity() {
  // initializing charityInfo as the state for this component
  const {
    checkoutPageState, updateCheckoutPageState, loading,
  } = useContext(CheckoutPageContext);
  const { charity: charityInfo, eSpots } = checkoutPageState;
  const [errorText, setErrorText] = useState('');
  const [loadingMutation, setLoadingMutation] = useState(false);
  const logger = useLog('checkout.charity');

  const [updateCharityMutation] = useMutation(CHARITY_UPDATE_MUTATION, {
    onCompleted: ({ updateCharity }) => {
      logger.debug('CHARITY_UPDATE_MUTATION RESULT', updateCharity);
      if (updateCharity?.responseInfo?.success) {
        updateCheckoutPageState(updateCharity);

        // trigger custom event with updated cart data for crs to capture
        const charityDoneEvent = new CustomEvent('charity:update:done', { detail: updateCharity.cartInfo });
        window.dispatchEvent(charityDoneEvent);
        // merge the checkout response to digitalData `cart`
        $window.digitalData?.merge('cart', {
          origin: 'checkout',
          data: updateCharity,
          action: 'charity_update',
        });
        setErrorText('');
      } else {
        setErrorText(updateCharity?.responseInfo?.statusMessages[0]?.message);
      }
      setTimeout(() => {
        setLoadingMutation(false);
      }, 900);
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      logger.error(`ERR: CHARITY_UPDATE_MUTATION: ${JSON.stringify(err)}`);
      setErrorText('Something went wrong. Cannot select charity!');
      setLoadingMutation(false);
    },
  });

  if (loading) {
    return <Loader classList="loader-charity" />;
  }

  const handleChange = (charityIndex, charityOptionIndex) => {
    if (loadingMutation) return;

    const newCharityInfo = charityInfo.map((charity, ci) => ({
      ...charity,
      option: charity.option.map((opt, oi) => ({
        ...opt,
        checked: (ci === charityIndex && oi === charityOptionIndex) ? !opt.checked : false,
      })),
    }));

    // prepare the charity object that needs to be updated in backend
    const updateCharity = {
      id: newCharityInfo[charityIndex].id,
      brand: newCharityInfo[charityIndex].brand,
      option: newCharityInfo[charityIndex].option[charityOptionIndex].id,
      flag: newCharityInfo[charityIndex].option[charityOptionIndex].checked ? 1 : 0,
    };

    setLoadingMutation(true);
    // run charity mutation
    updateCharityMutation({
      variables: {
        charityInfo: updateCharity,
      },
    });

    const charityAmount = charityInfo[charityIndex]?.option[charityOptionIndex]?.charityAmount;
    trackAction('universal_click', {
      data_text: charityAmount,
      data_action: 'add',
      event_type: 'click',
    });
  };

  const renderCharityCheckboxes = () => (
    charityInfo.map((charity, charityIndex) => (
      charity.option.map((charityOption, charityOptionIndex) => (
        <Checkbox
          key={`charityRoundUpSku_${charity.sku}_${charityOption.id}`}
          id={`charityRoundUpSku_${charity.sku}`}
          name="charityRoundUpSku"
          value={charity.name}
          onChange={() => handleChange(charityIndex, charityOptionIndex)}
          isChecked={charityOption.checked}
          data-charity-id={charity.id}
          data-charity-option={charityOption.id}
          description={(
            <div className="charity-checkbox-content">
              <span className="h4">{charity.name}</span>
            </div>
          )}
        />
      ))
    ))
  );

  const renderCharityTiles = () => (
    charityInfo.map((charity, charityIndex) => (
      <section className="charity-tiles__section" data-testid="charity-tiles__section" key={charity.id}>
        <Loader variant="hazy" isActive={loadingMutation} id="charity-loader">
          <TileInputGroup
            id="charity-tile-grp"
            classes="charity-tiles"
            isInvalid={!!errorText}
            errorMessageText={errorText}
          >
            {
            charity.option.map((charityOption, charityOptionIndex) => (
              <TileInput
                key={`charityRoundUpSku_${charity.sku}_${charityOption.id}`}
                id={`charityRoundUpSku_${charity.sku}_${charityOption.id}`}
                name="charityRoundUpSku"
                type="checkbox"
                variant="light-round"
                value={charityOption.name}
                onChange={() => handleChange(charityIndex, charityOptionIndex)}
                isChecked={charityOption.checked}
                data-charity-id={charity.id}
                data-charity-option={charityOption.id}
              />
            ))
          }
          </TileInputGroup>
        </Loader>
      </section>
    ))
  );

  return (
    <div className="charity__wrapper" data-testid="charity">
      {
        charityInfo && (
          <div className="charity-wrapper__checkbox">
            <MarketingSpot espotId="checkout_charityroundup" espotData={eSpots?.charityRoundup} />
            {
              charityInfo
                && charityInfo.length === 1
                && charityInfo[0].option.length > 1
                ? renderCharityTiles()
                : (
                  <CheckboxGroup
                    id="charity-checkbox-grp"
                    title=""
                  >
                    {!!errorText && <ErrorMessage>{errorText}</ErrorMessage>}
                    {renderCharityCheckboxes()}
                  </CheckboxGroup>
                )
            }
          </div>
        )
      }
    </div>
  );
}

Charity.defaultProps = {
  charityData: null,
};

Charity.propTypes = {};
