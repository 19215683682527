import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import trackAction from '../../../../tools/analytics';
import Tmnt from '../../../Tmnt/Tmnt';
import SwitchTestContext from '../../../../context/SwitchTestContext';

export default function LoggedOutView({
  tmntData,
  estimatedPoints,
  iconImageUrl,
  displayIconImage,
  isEmptyBag,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const signInTriggerCRSDep = digitalData && digitalData['chk-bag-checkout-signIn-trigger'];
  const sessionKey = tmntData?.sessionText?.key;
  let sessionValue = tmntData?.sessionText?.value;
  sessionValue = sessionValue.replace('{0}', estimatedPoints);
  const sessionTmntObject = {
    key: sessionKey,
    value: sessionValue,
  };
  const logo = displayIconImage ? 'logo-myabercrombie' : '';
  const handleLoginClick = useCallback(() => {
    trackAction('bag_signin_click', {
      data_text: 'sign in or join',
      data_action: 'open',
      event_type: 'click',
    });
  }, []);

  const handleClick = (event) => {
    handleLoginClick(event);
    event.preventDefault();

    if (!signInTriggerCRSDep) {
      // Dispatch the custom event
      const customEvent = new CustomEvent('authentication-modal:open', {
        detail: { isSignIn: true }, // event.current.attribute.data('isUserLogin')
      });
      document.dispatchEvent(customEvent);
    }
  };

  return (
    <div className="session">
      <div className="icon-heading ds-override" data-layout="horizontal" data-theme="">
        <div className="icon-heading-icon">
          {displayIconImage && <span className="icon-block-icon" data-icon={logo} aria-label={logo} />}
          {!displayIconImage
              && (
              <span className="icon-block-icon" data-icon={tmntData?.loyaltyLogoLabel?.value} aria-label={tmntData?.loyaltyLogoLabel?.value}>
                <img className="icon-block-icon-image" src={iconImageUrl} alt={tmntData?.account?.value} />
              </span>
              )}
          <div className="icon-heading-text">
            <span data-testid="iconHeadingSession">
              { !isEmptyBag
                ? <Tmnt tmnt={sessionTmntObject} isHtml />
                : (
                  <Tmnt tmnt={tmntData?.emptyBagLoggedOut} isHtml />
                )}
            </span>
            <span data-testid="iconHeadingReward" className="iconHeadingReward">
              <Tmnt tmnt={tmntData?.getRewarded} isHtml />
            </span>
          </div>
        </div>
        <div className="sign-in-join">
          <button className={`button ds-override js-open-modal ${signInTriggerCRSDep ? 'login-trigger' : ''}`} onClick={handleClick} data-brand="" type="button" data-modal="authentication-modal" data-is-user-login="true" data-theme="" data-variant="secondary-button">
            <span>
              <span className="screen-reader-text" />
              {!isEmptyBag ? (
                <Tmnt tmnt={tmntData?.signIn} isHtml />
              ) : (
                <Tmnt tmnt={tmntData?.emptyBagSignInButton} isHtml />
              )}

              <span className="screen-reader-text" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

LoggedOutView.defaultProps = {
  tmntData: {},
  estimatedPoints: '0',
  iconImageUrl: '',
  displayIconImage: false,
  isEmptyBag: false,

};

LoggedOutView.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  estimatedPoints: PropTypes.string,
  iconImageUrl: PropTypes.string,
  displayIconImage: PropTypes.bool,
  isEmptyBag: PropTypes.bool,
};
