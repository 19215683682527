import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputField, CardInput, PaymentIcon, ErrorMessage,
} from 'anf-core-react';
import { formatCardNumber, DEFAULT_ICON, DEFAULT_MAX_LENGTH } from './helpers/formatCard';
import { isValidCardNumber } from './helpers/cardValidations';

export default function CardNumber({
  cardNumber,
  label,
  errorMessage,
  index,
  onChange,
  cardTypesList,
}) {
  const [iconName, setIconName] = useState(DEFAULT_ICON);
  const [maxLength, setMaxLength] = useState(DEFAULT_MAX_LENGTH);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (event) => {
    const input = event.target.value;
    const formattedInput = formatCardNumber(input, cardTypesList);
    setIconName(formattedInput.type ? formattedInput.type : DEFAULT_ICON);
    setMaxLength(formattedInput.maxlength ? formattedInput.maxlength : DEFAULT_MAX_LENGTH);

    onChange(formattedInput);

    return formattedInput.formattedNumber;
  };

  const validateChange = (event) => {
    const input = event.target.value;
    setIsInvalid(!isValidCardNumber(input));
  };

  return (
    <CardInput
      paymentIcon={(
        <PaymentIcon
          iconName={iconName}
          labelText={null}
        />
      )}
      inputField={(
        <InputField
          id={`card-input-${index}`}
          label={label}
          name={`creditCard[${index}].cardNumber`}
          onChange={handleChange}
          onBlur={validateChange}
          type="tel"
          value={cardNumber}
          maxLength={maxLength}
          isInvalid={isInvalid}
          autoComplete=""
        >
          <ErrorMessage id="card-number-error-message">
            {errorMessage}
          </ErrorMessage>
        </InputField>
      )}
    />
  );
}

CardNumber.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  cardTypesList: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    maxlength: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    regex: PropTypes.string.isRequired,
  })).isRequired,
};
